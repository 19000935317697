<template>
  <div v-if="isAnchor" class="head-fixed" :style="{right: !$store.getters.isMobile ? '30px' : '0'}">
    <a-anchor :affix="false" wrapperClass="my-anchor" :targetOffset="10">
      <a-anchor-link v-for="(item, index) in linkList" :key="index" :href="'#' + item" :title="item" />
    </a-anchor>
  </div>
  <md-editor
    v-if="flag"
    v-model="text"
    :previewOnly="previewOnly"
    @onUploadImg="onUploadImg"
    :pageFullScreen="!$store.getters.isMobile"
    :style="{padding: !$store.getters.isMobile ? '0 20px' : '0 10px'}"
  />
  <div v-if="$store.getters.token" class="ex-fixed">
    <input style="display: none" name="infoMd" type="file" id="exampleInputFile" @change="changeFile">
    <div class="btn-group">
      <label for="exampleInputFile">
        <span style="cursor: pointer;display: block">上传</span>
      </label>
      <span @click="downFile">下载</span>
      <span @click="updateText">{{ previewOnly ? '修改' : '保存' }}</span>
      <span v-show="!previewOnly" @click="goBack">返回</span>
    </div>
  </div>
  <el-dialog v-model="dialogLogin" title="登录" center width="30%">
    <el-form :model="formLogin" :rules="rules" ref="ruleForm">
      <el-form-item label="账号" prop="username">
        <el-input v-model="formLogin.username"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="formLogin.password" show-password @keyup.enter="submitLogin"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogLogin = false">取消</el-button>
        <el-button type="primary" @click="submitLogin">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { defineComponent, ref, nextTick, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { getArticleDetail, updateArticle } from '@/api/article'
import { putOssStream } from '@/api/upload'
import MdEditor from 'md-editor-v3'
import 'md-editor-v3/lib/style.css'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import { Anchor, AnchorLink } from 'ant-design-vue'
export default defineComponent({
  name: 'Detail',
  components: { MdEditor, AAnchor: Anchor, AAnchorLink: AnchorLink },
  setup () {
    const store = useStore()
    const route = useRoute()
    const text = ref('')
    const flag = ref(true)
    const previewOnly = ref(true)
    const isAnchor = ref(true)
    let detail = null
    const linkList = reactive([])

    getArticleDetail({ id: route.params.id }).then(res => {
      text.value = res.data.content
      detail = res.data
      document.title = detail.title
      isAnchor.value = detail.tag === 'anchor'
      updateAnchor()
    })

    function updateAnchor () {
      if (isAnchor.value) {
        const matchs = text.value.match(/## (.*)(\n|\r)/g)
        // const matchs = text.value.match(/## (.*)\n|## (.*)\r/g)
        linkList.splice(0, linkList.length)
        if (matchs && matchs.length > 0) {
          matchs.forEach(item => {
            /## (.*)(\n|\r)/.test(item)
            // /## (.*)\n|## (.*)\r/.test(item)
            linkList.push(RegExp.$1)
            // linkList.push(RegExp.$1 || RegExp.$2)
          })
        }
      }
    }

    const updateText = async () => {
      if (!detail) return
      if (!store.getters.token) {
        ElMessage.error('未登录')
        return
      }
      if (!previewOnly.value) {
        try {
          const res = await updateArticle({
            author: detail.author,
            categoryId: detail.categoryId,
            cover: detail.cover,
            id: detail.id,
            introduction: detail.introduction,
            tag: detail.tag,
            title: detail.title,
            content: text.value
          })
          ElMessage.success(res.message)
          previewOnly.value = !previewOnly.value
          flag.value = false
          nextTick(() => {
            flag.value = true
          })
          updateAnchor()
        } catch (error) {
          dialogLogin.value = true
        }
      } else {
        previewOnly.value = !previewOnly.value
        flag.value = false
        nextTick(() => {
          flag.value = true
        })
      }
    }

    // 登录逻辑
    const ruleForm = ref(null)
    const dialogLogin = ref(false)
    const formLogin = reactive({ username: '', password: '' })
    const rules = reactive({
      username: [{ required: true }],
      password: [{ required: true }]
    })

    const submitLogin = () => {
      ruleForm.value.validate(val => {
        if (!val) return
        store.dispatch('user/getUserInfo', formLogin).then(res => {
          if (res) {
            dialogLogin.value = false
          }
        })
      })
    }

    const goBack = () => {
      previewOnly.value = !previewOnly.value
      flag.value = false
      nextTick(() => {
        flag.value = true
      })
    }

    // 图片上传
    const onUploadImg = async (files, callback) => {
      const res = await Promise.all(
        Array.from(files).map(file => {
          return new Promise((resolve, reject) => {
            const form = new FormData()
            form.append('file', file)
            form.append('category', `article/${detail.title}`)
            putOssStream(form)
              .then(res => resolve(res))
              .catch(error => reject(error))
          })
        })
      )
      callback(res.map(item => item.data[0]))
    }

    function changeFile (e) {
      const file = e.target.files[0]
      if (!file) return
      if (!/\.md$/.test(file.name)) {
        ElMessage.error('请上传markdown格式文件！')
        return
      }
      const reader = new FileReader()
      reader.onload = () => {
        text.value = reader.result
        updateArticle({
          author: detail.author,
          categoryId: detail.categoryId,
          cover: detail.cover,
          id: detail.id,
          introduction: detail.introduction,
          tag: detail.tag,
          title: detail.title,
          content: text.value
        }).then(() => {
          ElMessage.success('上传成功')
          updateAnchor()
        }).catch(() => {
          dialogLogin.value = true
        })
      }
      reader.readAsText(file, 'utf8')
    }

    function downFile () {
      const url = window.URL.createObjectURL(new Blob([text.value]))
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.href = url
      a.download = detail.title + '.md'
      a.target = '_blank'
      a.click()
      a.remove()
      window.URL.revokeObjectURL(url)
    }

    return {
      text,
      updateText,
      flag,
      previewOnly,
      ruleForm,
      dialogLogin,
      formLogin,
      rules,
      submitLogin,
      goBack,
      onUploadImg,
      isAnchor,
      linkList,
      changeFile,
      downFile
    }
  }
})
</script>

<style scoped lang="scss">
:deep.md {
  // padding: 0 20px;
  z-index: 999 !important;
  word-break: break-all;
  h1 {
    font-size: 2.5em;
    margin: 0.4em 0;
    text-align: center;
  }
  h2 {
    // font-size: 2.5em;
    // margin: 0.8em 0 0.2em;
    margin-top: 1em;
    text-align: center;
  }
  h3 {
    // font-size: 2em;
    // margin: 0.8em 0 0.2em;
    margin-top: 1em;
  }
  h4 {
    // font-size: 1.5em;
    margin: 0.8em 0 0.6em;
    font-size: 1.05em;
  }
  h5 {
    // font-size: 1.3em;
    margin: 0.8em 0 0.6em;
    // display: block;
    font-size: 1.05em;
    // margin-block-start: 0.83em;
    // margin-block-end: 0.83em;
    // margin-inline-start: 0px;
    // margin-inline-end: 0px;
  }
  p {
    // font-size: 18px;
    padding: 0.3rem 0;
    // margin: 0.2em 0;
  }
  li {
    // font-size: 18px;
    list-style: outside;
  }
  // .code-block {
  //   font-size: 16px;
  // }
  figure {
    width: 100%;
  }
  .default-theme blockquote {
    margin: 10px 0;
    padding: 0.3em 1.2em;
  }
}

.ex-fixed {
  position: fixed;
  height: 50px;
  width: 20px;
  background-color: #409eff;
  color: #fff;
  bottom: 50%;
  right: 0;
  cursor: pointer;
  line-height: 50px;
  text-align: center;
  z-index: 2020;
  transition: all 0.2s linear;
  transform: translateY(50%);
  border-radius: 8px;
  .btn-group {
    display: none;
  }
  &:hover {
    height: 200px;
    width: 50px;
    .btn-group {
      display: flex;
      flex-direction: column;
      span:hover {
        background-color: #79bbff;
      }
      span:active {
        background-color: #337ecc;
      }
    }
  }
}

.head-fixed {
  position: fixed;
  z-index: 1000;
  // right: 0;
  overflow-y: auto;
}

:deep.my-anchor {
  .ant-anchor-link {
    text-align: end;
  }
  .ant-anchor-link-title {
    text-decoration: none;
    color: #35485e;
  }
  .ant-anchor-link-title-active {
    color: #1890ff;
  }
}
</style>
